<script setup lang="ts">
const { locale } = useI18n();
const active = ref(false);
const mailInput = ref();
const emailContainer = ref(null);

watch(active, (value) => {
  const { focused } = useFocus(mailInput);
  focused.value = value;
});

const { enter } = useMagicKeys({ target: mailInput });

watch(enter, (value) => {
  if (!value) return;

  active.value = false;
});

if (import.meta.client) {
  onClickOutside(emailContainer, () => {
    active.value = false;
  });
}

// function getLocalizedCarrierPath(): string {
//   switch (locale.value) {
//     case "de-DE":
//       return "https://www.scheppach.com/de/karriere";
//     case "en-GB":
//       return "https://www.scheppach.com/en/career";
//     case "fr-FR":
//       return "https://www.scheppach.com/fr/carriere";
//   }
//   return "";
// }

const newsletterModalController = useModal();
const contact = {
  phone: "08223 / 40020",
  email: "info@scheppach.com",
};

onMounted(() => {
  if (window.location.hash.slice(1)) {
    const hashvalue = window.location.hash.slice(1);
    if (hashvalue == "newsletter") {
      newsletterModalController.open();
    }
  }
});
</script>

<template>
  <footer>
    <div class="flex flex-col">
      <div class="h-[0px] lg:h[80px]"></div>

      <!-- upper part -->
      <div class="bg-scheppach-primary-500 mt-25">
        <div
          class="flex flex-col custom-container lg:flex-row justify-center md:items-center relative bg-scheppach-primary-500 text-scheppach-shades-0 py-6 px-8 xl:p-6"
        >
          <div
            class="hidden lg:block text-[20px] xl:text-[40px] font-700 max-w-[257px] xl:max-w-[1024px] flex-grow"
          >
            {{ $t("footer.headline") }}
          </div>

          <div class="hidden md:block m-6">
            <div class="flex flex-col flex-grow gap-5">
              <div class="flex items-center text-[16px] font-400">
                <span
                  class="i-sl-form-validation-check-circle inline-block v-mid font-size-8 mr-2 min-w-[41px]"
                ></span>
                {{ $t("footer.checkedHeadline1") }}
              </div>
              <div class="flex items-center">
                <span
                  class="i-sl-form-validation-check-circle inline-block v-mid font-size-8 mr-2 min-w-[41px]"
                ></span>
                {{ $t("footer.checkedHeadline2") }}
              </div>
            </div>
          </div>

          <form method="get" name="newsletter">
            <div class="my-3 xl:m-6 xl:my-0 xl:max-w-80">
              <div class="text-center lg:text-left">
                <div class="text-[18px] xl:text-[24px] mb-3 sm:mb-5">
                  {{ $t("footer.subscribeNewsletterNow") }}
                </div>

                <SharedModal :controller="newsletterModalController">
                  <NewsletterOptinSetupStructure
                    optin-setup-structure-id="1"
                    :controller="newsletterModalController"
                  />
                </SharedModal>

                <div class="mx-auto">
                  <button
                    type="submit"
                    class="px-3 py-2 text-3.5 xl:text-3.8 font-bold rd b b-scheppach-primary-500 bg-white c-scheppach-primary-500 hover:bg-scheppach-primary-100"
                    @click.prevent="newsletterModalController.open"
                  >
                    {{ $t("checkout.summaryBox.subscribeNewsletter") }}
                  </button>
                </div>
              </div>
            </div>
          </form>

          <!-- svg letter -->
          <div class="hidden sm:block sm:m-0 w-75">
            <img
              class="hidden lg:block absolute bottom-0 right-5"
              src="/images/newsletter.webp"
              loading="lazy"
              alt="email-image"
              width="297px"
              height="329px"
            />
          </div>
        </div>
      </div>

      <!-- lower part -->
      <div class="bg-scheppach-primary-700 px-10 min-h-46">
        <div class="items-center c-scheppach-shades-0 xl:pt-10">
          <div
            class="flex flex-col-reverse lg:flex-row gap-15 my-6 xl:my-0 lg:flex-row lg:justify-around lg:w-full"
          >
            <div
              class="flex flex-row items-start gap-3 text-[20px] underline-offset-5"
            >
              <div
                class="flex flex-col sm:flex-row mx-auto items-center gap-5 sm:gap-2"
              >
                <NuxtLink
                  :to="$t('urls.career')"
                  class="hover:underline"
                  target="_blank"
                >
                  {{ $t("footer.career") }}
                </NuxtLink>
                <span class="text-3 hidden sm:inline">•</span>
                <NuxtLink :to="'/contact'" class="hover:underline">
                  {{ $t("footer.contact") }}</NuxtLink
                >
                <span class="text-3 hidden sm:inline">•</span>
                <NuxtLink
                  :to="`https://www.scheppach.com/${locale.substring(0, 2)}/faq`"
                  class="hover:underline"
                  target="_blank"
                  >{{ $t("footer.faq") }}
                </NuxtLink>
                <span class="text-3 hidden sm:inline">•</span>
                <NuxtLink
                  :to="$t('urls.factory-outlet')"
                  class="hover:underline"
                  target="_blank"
                >
                  {{ $t("footer.factory-sales") }}
                </NuxtLink>
                <span class="text-3 hidden sm:inline">•</span>
                <NuxtLink
                  :to="$t('urls.affiliate-program')"
                  class="hover:underline"
                  target="_blank"
                >
                  {{ $t("footer.affiliate-program") }}
                </NuxtLink>
              </div>
            </div>

            <div class="hidden xl:flex flex-grow flex-col">
              <div class="text-[20px] mb-1">
                {{ $t("footer.any-questions") }}
              </div>

              <div class="text-[16px]">
                <span>{{ contact.phone }}</span>
                <span class="mx-2 opacity-50 select-none"> | </span>
                <a :href="`mailto:${contact.email}`">
                  <span class="hover:underline underline-offset-5">{{
                    contact.email
                  }}</span>
                </a>
              </div>
            </div>

            <div
              class="order-first lg-order-none flex flex-grow flex-col mx-auto"
            >
              <div class="text-[20px] mb-2">
                {{ $t("footer.payment-methods") }}
              </div>

              <LayoutPaymentMethods />
            </div>

            <div class="flex flex-grow flex-col mx-auto">
              <div class="hidden lg:block text-[20px] xl:mt-0">
                {{ $t("footer.follow-us") }}
              </div>

              <div
                class="order-first lg:order-last font-size-[16-px] font-400 flex flex-wrap gap-3 mt-2 mb-5"
              >
                <NuxtLink
                  to="https://www.facebook.com/scheppach.group"
                  target="__blank"
                >
                  <img
                    class="hover:translate-y-[-5px] duration-300 ease-in-out w-[41px]"
                    src="/images/fb_circle.webp"
                    loading="lazy"
                    alt="facebook"
                  />
                </NuxtLink>
                <NuxtLink
                  to="https://www.instagram.com/scheppach_group/"
                  target="__blank"
                >
                  <img
                    class="hover:translate-y-[-5px] duration-300 ease-in-out w-[41px]"
                    src="/images/insta_circle.webp"
                    loading="lazy"
                    alt="instagram"
                  />
                </NuxtLink>
                <NuxtLink
                  to="https://www.xing.com/pages/scheppachgroup"
                  target="__blank"
                >
                  <img
                    class="hover:translate-y-[-5px] duration-300 ease-in-out w-[41px]"
                    src="/images/xing_circle.webp"
                    loading="lazy"
                    alt="xing"
                  />
                </NuxtLink>
                <NuxtLink
                  to="https://www.pinterest.de/scheppach_group/_created/"
                  target="__blank"
                >
                  <img
                    class="hover:translate-y-[-5px] duration-300 ease-in-out w-[41px]"
                    src="/images/pin_circle.webp"
                    loading="lazy"
                    alt="pinterest"
                  />
                </NuxtLink>
                <NuxtLink
                  to="https://www.youtube.com/user/scheppachgroup/videos"
                  target="__blank"
                >
                  <img
                    class="hover:translate-y-[-5px] duration-300 ease-in-out w-[41px]"
                    src="/images/yt_circle.webp"
                    loading="lazy"
                    alt="youtube"
                  />
                </NuxtLink>
                <NuxtLink
                  to="https://mt.linkedin.com/company/scheppach-group"
                  target="__blank"
                >
                  <img
                    class="hover:translate-y-[-5px] duration-300 ease-in-out w-[41px]"
                    src="/images/in_circle.webp"
                    loading="lazy"
                    alt="linkedin"
                  />
                </NuxtLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-scheppach-primary-700 px-10">
        <hr class="mx-auto h-[1px] bg-white" />
        <LayoutFooterSmall />
      </div>
    </div>
  </footer>
</template>
